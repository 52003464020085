import React, {useState} from 'react';
import './App.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { ethers,BigNumber,BytesLike } from 'ethers';
import {constants as ethersConst} from "ethers";
import LinearProgress from '@mui/material/LinearProgress';
import {
  Erc20__factory,
  Torches__factory,
  Masterchef__factory
} from './ethers-contracts/factories'
import { Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Multicall__factory } from './ethers-contracts';
import { PromiseOrValue } from './ethers-contracts/common';

// constants 
// 
const SKCS_ADDRESS = "0x00eE2d494258D6C5A30d6B6472A09b27121Ef451";
const TSKCS_ADDRESS = "0x0868713842d2e296CeF26c86d736AC7C374A5199";
const LPs = [
  { 
    desc: 'Mojito sKCS/MJT LP', 
    address: '0x42a82d898eb1d1688730709f4c2d99b537fee308', 
    masterchef: '0xfdfce767add9dcf032cbd0de35f0e57b04495324',
    poolID:   -1, // not enabled 
    startBlock: 12540477, 
  },
  { 
    desc: 'Kuswap KUS/sKCS LP', 
    address: '0x77a8d0ef377e7bccda40203acce300c170017570', 
    masterchef: '0x62974ce5d662f9045265716a3e64eaafc258779f',
    poolID:   16,
    startBlock: 12909546, 
  },
  { 
    desc: 'Mojito KCS/sKCS LP', 
    address: '0xa4e068d12adca07f99593e0133c6c01b01733acf', 
    masterchef: '0xfdfce767add9dcf032cbd0de35f0e57b04495324',
    poolID:   30,
    startBlock: 12073995, 
  },
  { 
    desc: 'Mojito sKCS/USDT LP', 
    address: '0xbaa085b3c7e0eb30d75190609fb0cb6e0db56820', 
    masterchef: '0xfdfce767add9dcf032cbd0de35f0e57b04495324',
    poolID:   29,
    startBlock: 12293131, 
  },
  { 
    desc: 'Kuswap KCS/sKCS LP', 
    address: '0xd8338546b0c7c07ba81f0dc3425fc4a25204756e', 
    masterchef: '0x62974ce5d662f9045265716a3e64eaafc258779f',
    poolID:   -1, // not enabled
    startBlock: 12910585, 
  },
 ]

// the global provider
const provider = new ethers.providers.JsonRpcProvider("https://rpc-mainnet.kcc.network");


interface BalanceInfo {
    description: string;
    primaryValues: Map<string,ethers.BigNumber>;
    secondaryValues: Map<string,ethers.BigNumber>;
}

// Same as getUserInfo, but aggregate calls with multicall  
async function getUserInfoMulti(user:string, block:number):Promise<{
  infos:BalanceInfo[];
  totalAmount:BigNumber;
}> {

  const multicall = Multicall__factory.connect("0xa64D6AFb48225BDA3259246cfb418c0b91de6D7a",provider);
  const skcs = Erc20__factory.connect(SKCS_ADDRESS,provider);
  const tskcs = Torches__factory.connect(TSKCS_ADDRESS,provider);


  // Aggregate calls 

  let calls:{
    target: PromiseOrValue<string>;
    callData: PromiseOrValue<BytesLike>;
  }[] = [];


  const actions = new Map<string,(results: string[])=>BigNumber>();

  // actions keys 
  const ActionKeyUserSKCSBal = ()=>"skcs.balance";
  const ActionKeyUserTSKCSBal = ()=>"tskcs.balance";
  const ActionKeyTorchesRate = () => "tskcs.rate";
  const ActionKeyUserLPBal = (lp:string) => `${lp}.user.lp`;
  const ActionKeyUserMasterChefLPBal = (lp:string) => `${lp}.user.master.lp`;
  const ActionKeyLPTotalSupply = (lp:string) => `${lp}.lp.supply`;
  const ActionKeyPairSKCSBal = (lp:string) => `${lp}.lp.skcs`;
 


  // skcs balance in user's wallet 
  if(block > 11892327){
    const i = calls.push({
      target: skcs.address,
      callData: skcs.interface.encodeFunctionData("balanceOf",[user])
    }) - 1; 
    actions.set(ActionKeyUserSKCSBal(),((ind)=> (results:string[])=>{
      const data=results[ind];
      return skcs.interface.decodeFunctionResult("balanceOf",data)[0] as BigNumber;
    })(i))
  }else{
    actions.set(ActionKeyUserSKCSBal(),()=>BigNumber.from(0));
  }

  // tSKCS balance in user's wallet 
  if(block > 12468903){

    // balance 
    let i = calls.push({
      target: tskcs.address,
      callData: tskcs.interface.encodeFunctionData("balanceOf",[user])
    }) - 1; 
    actions.set(ActionKeyUserTSKCSBal(),((ind)=> (results:string[])=>{
      const data=results[ind];
      return tskcs.interface.decodeFunctionResult("balanceOf",data)[0] as BigNumber;
    })(i))

    // conversion rate 
    i = calls.push({
      target: tskcs.address,
      callData: tskcs.interface.encodeFunctionData("exchangeRateCurrent")
    }) - 1; 
    actions.set(ActionKeyTorchesRate(),((ind)=> (results:string[])=>{
      const data=results[ind];
      return tskcs.interface.decodeFunctionResult("exchangeRateCurrent",data)[0] as BigNumber;
    })(i))    

  }else{
    actions.set(ActionKeyUserTSKCSBal(),()=>BigNumber.from(0));
    actions.set(ActionKeyTorchesRate(),()=>BigNumber.from(0));
  }

  // LP
  for(let j =0; j< LPs.length; ++j){
    const lp = LPs[j];

    const token = Erc20__factory.connect(lp.address,provider);
    const master = Masterchef__factory.connect(lp.masterchef,provider);    
    
    if(block > lp.startBlock){
      // user's LP in his wallet 
      let i = calls.push({
        target: token.address,
        callData: token.interface.encodeFunctionData("balanceOf",[user])
      }) - 1; 
      actions.set(ActionKeyUserLPBal(lp.desc),((ind)=> (results:string[])=>{
        const data=results[ind];
        return token.interface.decodeFunctionResult("balanceOf",data)[0] as BigNumber;
      })(i));

      // LP total supply 
      i = calls.push({
        target: token.address,
        callData: token.interface.encodeFunctionData("totalSupply"),
      }) - 1; 
      actions.set(ActionKeyLPTotalSupply(lp.desc),((ind)=> (results:string[])=>{
        const data=results[ind];
        return token.interface.decodeFunctionResult("totalSupply",data)[0] as BigNumber;
      })(i));      
    }else{
      actions.set(ActionKeyUserLPBal(lp.desc),()=>BigNumber.from(0));
      actions.set(ActionKeyLPTotalSupply(lp.desc),()=>BigNumber.from(0));      
    }

    // skcs in pair
    if(block > 11892327){

      let i = calls.push({
        target: skcs.address,
        callData: skcs.interface.encodeFunctionData("balanceOf",[token.address])
      }) - 1; 
      actions.set(ActionKeyPairSKCSBal(lp.desc),((ind)=> (results:string[])=>{
        const data=results[ind];
        return skcs.interface.decodeFunctionResult("balanceOf",data)[0] as BigNumber;
      })(i))

    }else{
      actions.set(ActionKeyPairSKCSBal(lp.desc), ()=>BigNumber.from(0));
    }

    // user's LP in masterchef 
    if(lp.poolID == -1 || block < lp.startBlock){
      actions.set(ActionKeyUserMasterChefLPBal(lp.desc),()=>BigNumber.from(0));
    }else{
      let i = calls.push({
        target: master.address,
        callData: master.interface.encodeFunctionData("userInfo",[lp.poolID,user])
      }) - 1; 
      actions.set(ActionKeyUserMasterChefLPBal(lp.desc),((ind)=> (results:string[])=>{
        const data=results[ind];
        return master.interface.decodeFunctionResult("userInfo",data)[0] as BigNumber;
      })(i))      
    }
  }

  const {returnData} = await multicall.aggregate(calls,{blockTag:block});


  // create infos 
  let infos:BalanceInfo[] = [];

  // summary 
  let totalAmountOfSKCS = BigNumber.from(0);


  // skcs in wallet 
  const userSKCSInWallet = actions.get(ActionKeyUserSKCSBal())!(returnData);
  totalAmountOfSKCS = totalAmountOfSKCS.add(userSKCSInWallet);
  infos.push({
    description: "sKCS",
    primaryValues: new Map<string,BigNumber>([
      ['user\'s balance in wallet', userSKCSInWallet],
    ]),
    secondaryValues: new Map<string,BigNumber>(),
  })

  // skcs in torches 
  const userTSKCSBal = actions.get(ActionKeyUserTSKCSBal())!(returnData);
  const torchesRate = actions.get(ActionKeyTorchesRate())!(returnData);
  totalAmountOfSKCS = totalAmountOfSKCS.add(
    userTSKCSBal.mul(torchesRate).div(ethersConst.WeiPerEther)
  )
  infos.push({
    description: "Torches sKCS",
    primaryValues: new Map<string,ethers.BigNumber>([
      ['user\'s tsKCS balance',userTSKCSBal],  
      ['indirect sKCS amount',userTSKCSBal.mul(torchesRate).div(ethersConst.WeiPerEther)],  
    ]),
    secondaryValues: new Map<string,BigNumber>([
      [`conversion rate`,torchesRate],   
    ]),
  })

  // skcs in LPs
  for(let i=0; i < LPs.length; ++i){

    const lp = LPs[i];

    const userLPInWallet = actions.get(ActionKeyUserLPBal(lp.desc))!(returnData);
    const lpTotalSupply = actions.get(ActionKeyLPTotalSupply(lp.desc))!(returnData);
    const userLPInMasterChef = actions.get(ActionKeyUserMasterChefLPBal(lp.desc))!(returnData);
    const skcsInPair = actions.get(ActionKeyPairSKCSBal(lp.desc))!(returnData);

    let userSKCSInLP = BigNumber.from(0);

    if(!lpTotalSupply.isZero()){
      userSKCSInLP = userLPInWallet.add(userLPInMasterChef).mul(skcsInPair).div(lpTotalSupply);
    }

    totalAmountOfSKCS = totalAmountOfSKCS.add(userSKCSInLP);

    infos.push({
      description: lp.desc,
      primaryValues: new Map<string,BigNumber>([
        ['user\'s LP in his wallet', userLPInWallet],
        ['user\'s LP in masterChef', userLPInMasterChef],
        ['indirect sKCS amount',userSKCSInLP]
      ]),
      secondaryValues: new Map<string,BigNumber>([
        ['LP total supply',lpTotalSupply],
        ['total sKCS in pair',skcsInPair],
      ]),
    })
  }


  // Parse Results 

  return {
    infos: infos,
    totalAmount: totalAmountOfSKCS,
  }

}


enum Phase {
  START, 
  INITIALIZING, 
  IDLE, // idle: waiting for user's input 
  QUERYING, // Querying Info 
  RESULT, 
  ERROR,
}


function App() {

  const [blockNumber, setBlockNumber]=useState(0)
  const [userAddr,setUserAddr] = useState("");
  const [phase, setPhase] = useState(Phase.START);
  const [balInfos, setBalInfos] = useState<{
    infos:BalanceInfo[];
    totalAmount:BigNumber;
  }>();
  const [errMsg, setErrMsg] = useState("");

  if(phase == Phase.START){
    // fetch latest block number
    provider.getBlockNumber().then((v)=>{
      setBlockNumber(v);
      setPhase(Phase.IDLE);
    }).catch(e=>{
       setPhase(Phase.ERROR);
       setErrMsg(e.toString());
    });
    setPhase(Phase.INITIALIZING);
  }



  return (
    <div className="App">
      {
        // initializing 
        phase == Phase.INITIALIZING &&  <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      }
      { 
        // idle 
        phase == Phase.IDLE &&  <Box
          component="form"  
          sx={{
            '& > :not(style)': { m: 1, width: '25ch' },
            border: '1px dashed grey',
            display: "flex",
            'justify-content': "center",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField id="user_address" sx={
            {
              minWidth: 500
            }
          } label="user's address" variant="outlined" value={userAddr} onChange={
                (e)=>{
                  setUserAddr(e.target.value);
                }
          }/>
          <TextField id="block_number" label="block number" variant="filled" value={blockNumber} onChange={
                (e)=>{
                  setBlockNumber(parseInt(e.target.value)||0);
                }
          }/>
          <Button variant="contained" onClick={()=>{
            setPhase(Phase.QUERYING);
            getUserInfoMulti(userAddr,blockNumber).then((result)=>{
                setPhase(Phase.RESULT);
                setBalInfos(result);
            }).catch(e=>{
                setErrMsg(e.toString());
                setPhase(Phase.ERROR);
            })
          }}>Go!</Button>
        </Box>
      }
      {
        // querying 
        phase == Phase.QUERYING &&  <Box sx={{ width: '100%' }}>
        <LinearProgress color="primary" />
        <LinearProgress color="secondary" />
        <LinearProgress color="success" />
        <LinearProgress color="inherit" />
        </Box>
      }
      {
        // results 
        phase == Phase.RESULT &&  <Box sx={{ width: '100%' }}>
          <Stack spacing={3}>
              <Typography sx={{textAlign: 'left'}} variant='h3' gutterBottom>
                 Summary
               </Typography>
               <Table sx={{ maxWidth: 900 }} aria-label="simple table">
                    <TableBody>
                       <TableRow>
                          <TableCell>block</TableCell><TableCell>#{blockNumber}</TableCell>
                       </TableRow>
                       <TableRow>
                          <TableCell>user address</TableCell><TableCell>{userAddr}</TableCell>
                       </TableRow>
                       <TableRow>
                          <TableCell>direct and indirect sKCS</TableCell>
                          <TableCell>{balInfos!.totalAmount.toString()} wei</TableCell>
                          <TableCell>{ethers.utils.formatEther(balInfos!.totalAmount)} ether</TableCell>
                       </TableRow>
                    </TableBody>
               </Table>
            {
              balInfos!.infos.map((v,i)=><Box>
               <Typography sx={{textAlign: 'left'}} variant='h4' gutterBottom>
                  {v.description}
               </Typography>
              
                <Table sx={{ maxWidth: 900 }} aria-label="simple table">
                  <TableBody>
                    {
                      Array.from(v.primaryValues.entries()).sort((a,b)=>{
                          // the property starting with 'user' comes first.
                          if(a[0].startsWith("user")){
                            return -1;
                          }
                          if(a[0].startsWith("indirect")){
                            return 1;
                          }
                          return 0;
                      }).map(v => <TableRow key={v[0]}>
                          <TableCell align="left">{v[0]}</TableCell>
                          <TableCell align="left">{v[1].toString()} wei</TableCell>
                          <TableCell align="left">{ethers.utils.formatEther(v[1])} ether</TableCell>
                      </TableRow>)
                    }
                    {
                        Array.from(v.secondaryValues.entries()).map(v => <TableRow key={v[0]}>
                          <TableCell sx={{
                              color: '#bdbcb9'
                          }} align="left">{v[0]}</TableCell>
                          <TableCell sx={{
                            color: '#bdbcb9'
                          }} align="left">{v[1].toString()} wei</TableCell>
                          <TableCell sx={{
                            color: '#bdbcb9'
                          }}align="left">{ethers.utils.formatEther(v[1])} ether</TableCell>
                      </TableRow>)                      

                    }
                  </TableBody>
                </Table>
                </Box>
            )}
          </Stack> 
        </Box>
      }
      {
        // error 
        phase == Phase.ERROR && <Box>
            <Typography variant='h4'>
              {errMsg}
            </Typography>
        </Box>
      }
    </div>
  );
}

export default App;
